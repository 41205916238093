import { useLayoutQuery } from '@/store/api';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { StrapiSeo as SeoType, ShortSeo } from '~shared';

interface SeoProps {
  seo?: SeoType | ShortSeo | null;
}

const Seo = ({ seo }: SeoProps): JSX.Element => {
  const { locale, isFallback, ...router } = useRouter();
  const { defaultSeo } = useLayoutQuery(
    { locale },
    {
      skip: isFallback,
      selectFromResult: ({ data }) => ({
        defaultSeo: data?.defaultSeo || undefined,
      }),
    },
  );

  const title = useMemo(() => {
    const title = seo?.metaTitle
      ? `${seo?.metaTitle} | ${defaultSeo?.defaultTitlePostfix}`
      : defaultSeo?.defaultEmptyTitle ?? undefined;
    return title;
  }, [seo, defaultSeo]);

  const keywords = useMemo(
    () => seo?.keywords ?? defaultSeo?.defaultKeywords ?? undefined,
    [seo, defaultSeo],
  );
  const description = useMemo(
    () => seo?.metaDescription ?? defaultSeo?.defaultDescription ?? undefined,
    [seo, defaultSeo],
  );
  const canonicalUrl = useMemo(() => {
    const canonicalUrlPostfix = seo?.canonicalURL ?? router.asPath;
    const canonicalUrlPrefix = process.env.NEXT_PUBLIC_URL;
    return `${canonicalUrlPrefix}${canonicalUrlPostfix}`;
  }, [seo, defaultSeo, router.asPath]);

  const metaImage = useMemo(() => {
    const image =
      seo?.metaImage?.url ?? defaultSeo?.defaultMetaImage?.url ?? undefined;
    return `${process.env.NEXT_PUBLIC_API_URL}${image}`;
  }, [seo, defaultSeo]);

  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="icon" href="/favicon.ico" />

      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={title ?? undefined} />
      <meta property="og:image" content={metaImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={canonicalUrl} />
      <meta name="twitter:site" content={process.env.NEXT_PUBLIC_URL} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={metaImage} />

      <link rel="canonical" href={canonicalUrl} />
    </Head>
  );
};

export default Seo;
