import { MTypography } from '../typography';
import { Variant } from '../typography/Typography';
import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';

export const AnimatedText = ({
  text,
  variant,
  className,
  opacity = 1,
}: {
  text: string | null | undefined;
  variant: Variant;
  className?: string;
  opacity?: number
}) => {
  return (
    <AnimatePresence>
      <MTypography
        key={text}
        initial={{ opacity: 0 }}
        animate={{ opacity }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        variant={variant}
        className={cn('absolute inset-0', className)}
        as="span"
      >
          <span dangerouslySetInnerHTML={{ __html: text ?? '' }}/>
      </MTypography>
    </AnimatePresence>
  );
};
